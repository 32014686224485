<template>
  <v-container fill-height fluid class="gray lighten-4">
    <v-layout justify-center class="mt-15">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12" max-width="400" min-width="250">
          <v-card-title class="black--text mt-8 d-flex justify-center">
            ยินดีต้อนรับ - {{ nickname }}
          </v-card-title>
          <v-card-subtitle class="black--text mt-1 d-flex justify-center"
            >เข้าสู่ระบบครั้งแรกโปรดสร้างรหัสยืนยัน</v-card-subtitle
          >
          <v-card-text class="py-4 white">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <CodeInput
                  :loading="false"
                  :autoFocus="true"
                  :fields="4"
                  class="input"
                  v-on:complete="onComplete"
                  ref="ci"
                />
                <!-- v-on:change="onChange" -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "CreatePincode",
  components: {
    CodeInput,
  },
  methods: {
    // onChange(v) {
    //   console.log("onChange ", v);
    // },
    onComplete(v) {
      //   console.log("onComplete ", v);
      this.do_verify(v);
      //save pin code
    },
    async do_verify(p_code) {
      let parameter = {
        verify_code: p_code,
      };

      this.$store.dispatch("auth/create_pincode", parameter).then(
        (response) => {
          if (response == "Success") {
            this.$router.push("/Menu_Data");
          }
        },
        (error) => {
          // console.log(localStorage.getItem("blog_token"));
          switch (error) {
            case "TokenError":
              // window.location.reload();

              alert(error);
              break;
            default:
              alert(error);
              break;
          }

          //   this.login_message(error);
        }
      );
    },
  },
  data: () => ({
    nickname: sessionStorage.getItem("nickname"),
    code: "",
  }),
};
</script>

<style></style>
